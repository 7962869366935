<template>
  <div
    class="surface-card p-4 shadow-2 border-round w-full lg:w-6"
    style="margin: auto"
  >
    <div class="text-center mb-5">
      <img
        :src="publicPath + 'images/logo-white.svg'"
        alt="Image"
        height="100"
      />
      <div class="text-900 text-3xl font-medium mb-3">
        Cuenta confirmada con éxito
      </div>
    </div>

    <div>
      <p class="text-900 text-3l font-medium mb-3 text-center">
        El email <span class="highlight">{{ email }}</span> ha sido validado
        correctamente
      </p>
      <Button
        label="Volver al dashboard"
        icon="pi pi-user"
        @click="goHome()"
        class="w-full"
      ></Button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const email = this.$route.query?.email ?? '';
    return {
      publicPath: process.env.BASE_URL,
      email,
    };
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
  },
};
</script>
<style scoped>
.highlight {
  color: var(--indigo-500);
}
</style>
